<template>
  <div v-if="product" class="product__images">
    <img
      :src="previewImg || product.primary_image"
      alt
      id="main-image"
      class="product__main-image"
      @error="(e) => appUtils.onHandleErrorLoadImage(e)"
    />
    <div class="product__slider-wrap" id="slider-wraper">
      <div @click="scrollLeft" v-if="showArrow">
        <img
          src="../../../public/icons/icon-arrow-left-circle.svg"
          alt
          width="36px"
          height="36px"
          class="arrow"
          id="slideRight"
        />
      </div>

      <div class="product__slider" id="slider">
        <div v-for="(item, index) in images" :key="index">
          <img
            :src="item.src"
            :alt="item.alt"
            class="product__image"
            @mouseover="handleHover(item)"
            @mouseleave="previewImg = product.primary_image"
            @error="(e) => appUtils.onHandleErrorLoadImage(e)"
          />
        </div>
      </div>
      <div @click="scrollRight" v-if="showArrow">
        <img
          src="../../../public/icons/icon-arrow-right-circle.svg"
          alt
          width="36px"
          height="36px"
          class="arrow"
          id="slideLeft"
        />
      </div>
    </div>
  </div>
</template>

<script>
import appUtils from '@/utils/appUtils'
export default {
  name: 'ProductImages',
  components: { appUtils },
  setup() {
    return {
      appUtils
    }
  },
  props: {
    product: Object
  },
  data() {
    return {
      previewImg: '',
      btnRight: document.getElementById('slideRight'),
      btnLeft: document.getElementById('slideLeft')
    }
  },
  computed: {
    images() {
      return this.product?.thumbnail_image
        ? JSON.parse(this.product?.thumbnail_image)?.map(elm => {
            return {
              src: elm.url,
              alt: ''
            }
          }) || `[]`
        : []
    },
    showArrow() {
      return this.images.length
    }
  },
  methods: {
    scrollLeft() {
      document.getElementById('slider').scrollLeft -= 80
    },
    scrollRight() {
      document.getElementById('slider').scrollLeft += 80
    },
    handleHover(item) {
      this.previewImg = item.src
    }
  }
}
</script>

<style lang="scss" scoped>
.product__images {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  //   justify-content: center;
  align-items: center;
  .product__main-image {
    height: 240px;
    width: 300px;
    object-fit: contain;
    border-radius: 8px;
    // box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
    @media screen and (min-width: 768px) {
      height: 300px;
    }
  }
  .product__slider-wrap {
    max-width: 400px;
    margin-top: 16px;
    width: 100%;
    gap: 8px;
    display: flex;
    align-items: center;

    .arrow {
      cursor: pointer;
      transition: 0.3s;
      &:hover {
        // opacity: 0.5;
        width: 40px;
        height: 40px;
      }
    }
    .product__slider {
      gap: 8px;
      border-radius: 4px;
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      overflow-x: hidden;
      width: 100%;
      transition: all ease-in-out 0.5s;
      padding-top: 12px;
      & div {
        width: 80px;
      }
      .product__image {
        box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
        border: 1px solid #000;
        cursor: pointer;
        border-radius: 4px;
        width: 80px;
        height: 80px;
        object-fit: cover;
        opacity: 0.5;
        bottom: 0;
        position: relative;
        max-width: unset;

        transition: all ease 0.5s;
        &:hover {
          opacity: 1;
          transform: translateY(-8px);
        }
      }
      .product__image--active {
        opacity: 1;
      }
    }
  }
}
.product__images {
  ::v-deep.el-carousel .el-carousel__indicators {
    display: none;
  }
}
</style>

<template>
  <section class="app-ecommerce-details">
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="product === undefined">
      <h4 class="alert-heading">Error fetching product data</h4>
      <div class="alert-body">
        No item found with this item slug. Check
        <b-link class="alert-link" :to="{ name: 'Marketplace' }">Shop</b-link>for other items.
      </div>
    </b-alert>

    <!-- Content -->
    <b-card v-if="product" no-body>
      <b-card-body>
        <b-row class="mt-2">
          <!-- Left: Product Image Container -->
          <b-col
            cols="12"
            md="5"
            class="d-flex align-items-center justify-content-center mb-2 mb-md-0"
          >
            <div class="d-flex align-items-center justify-content-center">
              <!-- <b-img
                :src="product.primary_image"
                :alt="`Image of ${product.name}`"
                class="product-img"
                fluid
              />-->
              <product-images :product="product"></product-images>
            </div>
          </b-col>

          <!-- Right: Product Details -->
          <b-col cols="12" md="7">
            <!-- Product Name -->
            <h4 class="font-semibold">{{ product.name }}</h4>

            <!-- Product Brand -->
            <b-card-text class="item-company mb-0 fs-16 font-medium" v-if="product.brands">
              <span v-if="product.brands.id">từ</span>
              <b-link style="margin-left: 4px" v-if="product.brands.id">{{ product.brands.name }}</b-link>
            </b-card-text>

            <!-- Price And Ratings -->
            <div class="ecommerce-details-price d-flex flex-wrap mt-1 mb-2">
              <h4
                class="item-price mr-1 fs-20 font-bold mb-0"
                style="color: #20419b"
              >{{ appUtils.numberFormat(price) }}đ / {{ variant }}</h4>
              <ul class="unstyled-list list-inline pl-1 border-left mb-0 flex items-center">
                <li v-for="star in 5" :key="star" class="ratings-list-item mr-25">
                  <feather-icon
                    icon="StarIcon"
                    size="18"
                    :class="[
                      { 'fill-current': star <= 5 },
                      star <= 5 ? 'text-warning' : 'text-muted',
                    ]"
                  />
                </li>
              </ul>
            </div>

            <!-- Avability -->
            <b-card-text>
              <span class="font-medium" style="margin-right: 4px">Tình trạng -</span>
              <span class="text-success" v-if="balance > 0">Còn hàng</span>
              <span class="text-warning" v-else>Hết hàng</span>
            </b-card-text>

            <b-card-text v-if="product.type === 1 && product.category.id">
              <span class="font-medium">Danh mục:</span>
              {{ listCategory }}
            </b-card-text>
            <b-card-text v-if="product.type === 1 && product.dosage_form">
              <span class="font-medium">Dạng bào chế:</span>
              {{ product.dosage_form }}
            </b-card-text>
            <b-card-text v-if="product.specifications">
              <span class="font-medium">Quy cách đóng gói:</span>
              {{ product.specifications }}
            </b-card-text>
            <b-card-text v-if="product.ingredient">
              <span class="font-medium">Thành phần:</span>
              {{ product.ingredient }}
            </b-card-text>
            <b-card-text v-if="product.type === 1 && product.indication">
              <span class="font-medium">Chỉ định:</span>
              {{ product.indication }}
            </b-card-text>
            <b-card-text v-if="product.type === 1 && product.contraindicated">
              <span class="font-medium">Chống chỉ định:</span>
              {{ product.contraindicated }}
            </b-card-text>
            <b-card-text v-if="product.short_desc">
              <span class="font-medium">Mô tả ngắn:</span>
              {{ product.short_desc }}
            </b-card-text>
            <b-card-text v-if="product.origin">
              <span class="font-medium">Nguồn gốc:</span>
              {{ product.origin }}
            </b-card-text>
            <b-card-text v-if="product.manufacturer">
              <span class="font-medium">Nhà sản xuất:</span>
              {{ product.manufacturer }}
            </b-card-text>

            <hr />
            <div
              class="flex items-center gap-2 text-black mb-1 choose-variant"
              v-if="product.variants"
            >
              <span class="font-medium">Đơn vị tính:</span>
              <div v-for="item in product.variants" :key="item.id" class="flex items-center gap-3">
                <b-form-group v-slot="{ ariaDescribedby }">
                  <b-form-radio
                    v-model="variantActive"
                    :aria-describedby="ariaDescribedby"
                    :value="item"
                  >
                    <span class="text-black text-lg">{{ item.ecom_unit ? item.ecom_unit.name : "" }}</span>
                  </b-form-radio>
                </b-form-group>
              </div>
            </div>
            <div class="flex items-center gap-4">
              <b-card-text class="mb-0">Chọn số lượng</b-card-text>
              <el-input-number
                size="small"
                :min="1"
                :max="variantActive.ecom_warehouse_variants.balance || 100"
                v-model="number"
              ></el-input-number>
            </div>

            <div class="d-flex flex-sm-row pt-1 gap-3 w-full justify-center lg:justify-start">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="btn-cart mr-0 mb-sm-0"
                :class="isDisableBtn ? 'cursor-not-allowed' : ''"
                :disabled="!balance"
                @click="handleBuyProduct(product)"
              >
                <span>Chọn mua</span>
              </b-button>
              <b-button
                variant="outline-secondary"
                class="btn-wishlist mr-0 mb-sm-0 flex-sm-none flex items-center justify-center"
                :class="isDisableBtn ? 'cursor-not-allowed' : ''"
                @click="handleAddToCart(product)"
                :disabled="!balance"
              >
                <!-- <feather-icon icon="ShoppingCartIcon" class="mr-50" /> -->
                <span>Thêm vào giỏ hàng</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-card-body>
      <div class="px-2 py-1 lg:p-2">
        <h4 class="font-semibold">Mô tả sản phẩm</h4>
        <p v-html="product.description" class="description"></p>
      </div>

      <!-- Static Content -->
      <!-- <e-commerce-product-details-item-features /> -->

      <!-- Static Content -->
      <!-- Slider: Related Products -->
      <!-- <e-commerce-product-details-related-products /> -->
    </b-card>
  </section>
</template>

<script>
import store from '@/store'
import { ref } from '@vue/composition-api'
import {
  BAlert,
  BButton,
  BCard,
  BCardBody,
  BCardText,
  BCol,
  BDropdown,
  BDropdownItem,
  BImg,
  BLink,
  BRow
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
// import ECommerceProductDetailsItemFeatures from './ECommerceProductDetailsItemFeatures.vue'
// import ECommerceProductDetailsRelatedProducts from './ECommerceProductDetailsRelatedProducts.vue'
import ProductImages from '@/components/marketplace/ProductImages.vue'
import appUtils from '@/utils/appUtils'
import { useEcommerceUi } from '@/views/pages/e-commerce/useEcommerce'

export default {
  name: 'ProductInfo',
  directives: {
    Ripple
  },
  components: {
    // BSV
    BCard,
    BCardBody,
    BRow,
    BCol,
    BImg,
    BCardText,
    BLink,
    BButton,
    BDropdown,
    BDropdownItem,
    BAlert,
    ProductImages

    // SFC
    // ECommerceProductDetailsItemFeatures,
    // ECommerceProductDetailsRelatedProducts
  },
  computed: {
    defaultVariants() {
      return (
        this.product?.variants?.find(elm => elm.default === 2) ||
        this.product?.variants?.find(elm => elm.conversion_rate === 1) ||
        {}
      )
    },
    isDisableBtn() {
      return !this.balance
    },
    listCategory() {
      return this.product?.ecom_product_category
        ?.map(elm => ` ${elm.ecom_category?.name}`)
        .toString()
    }
  },
  methods: {
    async getDetailProduct() {
      await store
        .dispatch('app-ecommerce/fetchProduct', this.$route.params.slug)
        .then(res => {
          this.product = res.data
          this.variantActive = this.defaultVariants.ecom_warehouse_variants
            .balance
            ? this.defaultVariants
            : this.product?.variants?.find(
                elm => elm.ecom_warehouse_variants.balance
              ) || this.defaultVariants
          this.price = this.variantActive?.price
          this.variant = this.variantActive?.ecom_unit?.variant
        })
        .catch(e => {
          console.log(e)
        })
    },
    handleBuyProduct() {
      this.appUtils.addToCart(this.product, this.variantActive?.id, this.number)
      this.$router
        .push({
          name: 'ShoppingCart'
        })
        .catch(() => {})
    },
    handleAddToCart() {
      this.appUtils.addToCart(this.product, this.variantActive?.id, this.number)
      this.$message.success(
        `Bạn đã thêm ${this.number} sản phẩm này vào giỏ hàng!`
      )
    }
  },
  watch: {
    variantActive: {
      handler(value) {
        this.price =
          this.product?.variants?.find(elm => elm.id == value.id)?.price || 0
        const selectedVariant = this.product?.variants?.find(
          elm => elm.id == value.id
        )
        this.variant = selectedVariant?.ecom_unit?.name || ''
        this.balance = selectedVariant?.ecom_warehouse_variants?.balance
        this.product = {
          ...this.product,
          selected_variant: {
            ...selectedVariant
          }
        }
      }
    },
    '$route.params.slug': {
      handler(value) {
        this.getDetailProduct()
      }
    }
  },
  data() {
    return {
      product: {},
      variantActive: {
        ecom_warehouse_variants: {
          balance: 0
        }
      },
      number: 1,
      price: 0,
      variant: '',
      balance: ''
    }
  },
  async mounted() {
    await this.getDetailProduct()
    this.product.selected_variant = this.defaultVariants
  },
  setup() {
    const { handleCartActionClick, toggleProductInWishlist } = useEcommerceUi()

    // UI
    const selectedColor = ref(null)

    return {
      appUtils,
      // Fetched Product
      selectedColor,
      handleCartActionClick,
      toggleProductInWishlist
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/pages/app-ecommerce-details.scss';
.card-text {
  font-size: 16px;
  line-height: 24px;
  color: #000;
  @media screen and (max-width: 768px) {
    font-size: 14px !important;
    line-height: 20px !important;
  }
}
.card-body {
  margin-bottom: 20px;
  @media screen and (max-width: 768px) {
    margin-bottom: 0;
  }
}
h4 {
  color: #000;
  font-size: 20px;
  line-height: 32px;
  @media screen and (max-width: 768px) {
    font-size: 16px !important;
    line-height: 24px !important;
  }
}
.form-group {
  margin-bottom: 0;
}
.choose-variant {
  font-size: 16px;
  line-height: 24px;
  @media screen and (max-width: 768px) {
    font-size: 14px !important;
    line-height: 20px !important;
  }
}
::v-deep.description {
  img {
    width: 100%;
    object-fit: cover;
  }
}
</style>
